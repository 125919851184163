import React from 'react'
// import Joinimage from './Joinimage'
// import downloadFile from 'gallery/parents-guide.pdf' 
import config from '../../data/SiteConfig'


const Troopwebhost = () => (
  <>
    <h1>Troop WebHost</h1>

    

    
    
<p><a href='https://www.troopwebhost.org/Troop622Bothell/Index.htm'>Troop WebHost</a> is our site for troop members</p>
  </>
)

export default Troopwebhost
