import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../layout'
import Troopwebhost from '../components/Troopwebhost'
import config from '../../data/SiteConfig'

const TroopwebhostPage = () => (
  <Layout>
    <main>
      <Helmet title={`WebHost | ${config.siteTitle}`}>
	</Helmet>	
	<Troopwebhost />
    </main>
  </Layout>
  )

  export default TroopwebhostPage